import React, { useState, useEffect, useContext, } from "react";
import { daysOptions, monthsOptions, yearOptions } from "../../../constant/selectConstants";
import { Form, Upload, Spin } from "antd";
import { addBarangay } from "services/public_registration.service";
import {
  Wrapper,
  Header,
  UploadImageAvatar,
  SectionHeader,
  FormItem,
  InputTextFields,
  InputDateSelectField,
  InputPhoneNumberField,
  InputSelectField,
  BirthdateSelectContainer,
  ReuploadBorder,
} from "./EditForm.styled";
import {
  UserOutlined,
  PushpinOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import { SearchContext } from "../SearchContext";
import { uploadCitizenPhoto } from "../../../services/public_registration.service";
import ImageCropperDialog from "components/ImageCropper";
import { validators } from "utils/validators";

const EditForm = ({ getFormRef }) => {
  const [forms] = Form.useForm();
  const {
    state,
    stateDispatchMain,
    formatPhoneNumber,
    genderOptions,
    parsePhoneNumber,
    setSelectedProvince,
    selectedProvince,
    provinceOptions,
    setSelectedMunicipality,
    selectedMunicipality,
    municipalityOptions,
    barangayOptions,
    isLoading,
  } = useContext(SearchContext);

  const [showAddBarangay, setShowAddBarangay] = useState(false);

  const [image, setImage] = useState("");
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [imageCropper, setImageCropper] = useState({
    isOpen: false,
    imageFile: null,
  });

  const validImageFormats = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  const onUpload = async file => {
    const uploadedMedia = await uploadCitizenPhoto(file);

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...state.citizenDetails,
        profile: uploadedMedia.url,
      },
    });
    setIsLoadingImage(false);
    setImage(URL.createObjectURL(file));
    setImageCropper({
      isOpen: false,
      imageFile: null,
    });
  };

  const onSelect = async ({ file, onError }) => {
    setIsLoadingImage(true);
    if (!validImageFormats.includes(file?.type)) {
      setTimeout(() => {
        onError("error");
      }, 100);
      return;
    }

    setImageCropper({
      isOpen: true,
      imageFile: file,
    });
  };

  const props = {
    accept: "image/*",
    name: name,
    customRequest: onSelect,
  };

  useEffect(() => {
    const year = forms?.getFieldValue("birthdate_year");
    const month = forms?.getFieldValue("birthdate_month");
    const date = forms?.getFieldValue("birthdate_day");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        forms?.setFieldsValue({ date: undefined });
      }
    }
  }, [forms?.getFieldValue("birthdate_month"), forms?.getFieldValue("birthdate_year")]);

  useEffect(() => {
    // Set the initial form values from the state when the component mounts
    // and whenever the state.citizenDetails changes
    forms.setFieldsValue(state.citizenDetails);
  }, [state.citizenDetails]);

  return (
    <Wrapper form={forms} initialValues={state.citizenDetails} ref={(formRef) => getFormRef ? getFormRef(formRef) : null}>
      <Header>
        <div style={{ margin: "30px 0" }}>
          {isLoadingImage ? <Spin /> : <UploadImageAvatar src={image || state.citizenDetails.profile} />}
          <div className="title">
            <span>{`${state.citizenDetails.last_name}, ${state.citizenDetails.first_name} ${state.citizenDetails.name_extension}`}</span>
            <div className="upload-container">
              <Upload {...props} showUploadList={false}>
                <ReuploadBorder
                  type="dashed"
                  icon={<CloudUploadOutlined style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "10px" }} />}>
                  <span>I-open ang camera o ang gallery.</span>
                  <span className="upload-click">Click here.</span>
                </ReuploadBorder>
              </Upload>
            </div>
            <ImageCropperDialog
              open={imageCropper.isOpen}
              onClose={() => {
                setImageCropper({
                  isOpen: false,
                  imageFile: null,
                });

                setIsLoadingImage(false);
              }}
              imageFile={imageCropper.imageFile}
              getImage={onUpload}
            />
          </div>
        </div>
      </Header>
      <div>
        <SectionHeader>
          <UserOutlined className="icon" color="#2d378c" />
          <span>Personal Information</span>
        </SectionHeader>
      </div>
      <div>
        <FormItem label="Pangalan" name="first_name" rules={[
          { required: true, message: "First name is required" },
          validators.name
        ]}>
          <InputTextFields
            name="first_name"
            value={state.citizenDetails.first_name}
            onChange={e => {
              const inputValue = e.target.value;
              if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                // Only allow letters and spaces
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    first_name: inputValue,
                  },
                });
              }
            }}
          />
        </FormItem>
        <FormItem label="Apelyido" name="last_name" rules={[{ required: true, message: "Last name is required" },
        validators.name]}>
          <InputTextFields
            name="last_name"
            value={state.citizenDetails.last_name}
            onChange={e => {
              const inputValue = e.target.value;
              if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                // Only allow letters and spaces
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    last_name: inputValue,
                  },
                });
              }
            }}
          />
        </FormItem>
        <FormItem label="Ext (Sr,Jr,I,II,III)" name="name_extension"
          rules={[
            validators.name,
            {
              max: 10,
              message: 'Name extension cannot be longer than 10 characters'
            }
          ]}>
          <InputTextFields
            name="name_extension"
            value={state.citizenDetails.name_extension}
            onChange={e => {
              const inputValue = e.target.value;
              if (/^[a-zA-Z\s]*$/.test(inputValue)) {
                // Only allow letters and spaces
                stateDispatchMain({
                  key: "citizenDetails",
                  payload: {
                    ...state.citizenDetails,
                    name_extension: inputValue,
                  },
                });
              }
            }}
          />
        </FormItem>
        <FormItem label="Kasarian/Sex" name="gender" rules={[{ required: true, message: "Gender is required" }]}>
          <InputSelectField
            showSearch
            name="gender"
            bordered={false}
            optionFilterProp="children"
            onChange={value => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  gender: value,
                },
              });
            }}
            options={genderOptions}
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </FormItem>
        <FormItem label="Birthdate">
          <BirthdateSelectContainer>
            <FormItem
              className="personal-info-date-select"
              label="Year"
              name="birthdate_year"
              rules={[{ required: true, message: "Year is required." }]}>
              <InputDateSelectField
                options={yearOptions()}
                showSearch
                allowClear
                onChange={value => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      birthdate_year: value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              className="personal-info-date-select"
              label="Month"
              name="birthdate_month"
              rules={[{ required: true, message: "Month is required." }]}>
              <InputDateSelectField
                options={monthsOptions()}
                filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                showSearch
                allowClear
                onChange={value => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      birthdate_month: value,
                    },
                  });
                }}
              />
            </FormItem>
            <FormItem
              className="personal-info-date-select"
              label="Date"
              name="birthdate_day"
              rules={[{ required: true, message: "Date is required." }]}>
              <InputDateSelectField
                options={daysOptions(forms?.getFieldValue("birthdate_month"), forms?.getFieldValue("birthdate_year"))}
                disabled={!forms?.getFieldValue("birthdate_year") || !forms?.getFieldValue("birthdate_month")}
                showSearch
                allowClear
                onChange={value => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      birthdate_day: value,
                    },
                  });
                }}
              />
            </FormItem>
          </BirthdateSelectContainer>
        </FormItem>
        <FormItem
          label="Mobile Number"
          name="phone_number"
          validateTrigger={["onBlur"]}
          rules={[
            { required: true, message: "Mobile number is required" },
            {
              validator: async (_, value) => {
                if (value.toString().length < 10) {
                  return Promise.reject("Incorrect mobile number format");
                } else {
                  return Promise.resolve();
                }
              },
              message: "Incorrect mobile number format",
            },
          ]}>
          <InputPhoneNumberField
            name="phone_number"
            autoComplete="off"
            value={state.citizenDetails.phone_number}
            formatter={formatPhoneNumber}
            parser={parsePhoneNumber}
            onChange={value => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  phone_number: value,
                },
              });
            }}
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            defaultValue={63}
            maxLength={12}
            minLength={12}
          />
        </FormItem>
        <FormItem
          label="Facebook Profile Link"
          name="fb_profile_link"
          rules={[
            {
              validator: async (_, value) => {
                const pattern = /^https:\/\/(www\.)?(facebook\.com|fb\.com)\/[a-zA-Z0-9.]{5,}\/?$/;
                if (value && !pattern.test(value)) {
                  return Promise.reject("Invalid facebook profile link");
                } else {
                  return Promise.resolve();
                }
              },
              message: "Invalid facebook profile link",
            },
          ]}
          extra="https://facebook.com/juan.delacruz"
        >
          <InputTextFields
            name="fb_profile_link"
            value={state.citizenDetails.fb_profile_link}
            onChange={e =>
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  fb_profile_link: e.target.value,
                },
              })
            }
          />
        </FormItem>
      </div>
      <hr />
      <div>
        <SectionHeader>
          <div>
            <PushpinOutlined color="#2d378c" className="icon" />
            <span>Address</span>
          </div>
        </SectionHeader>
      </div>
      <div>
        <FormItem label="Probinsya" name="province" rules={[{ required: true, message: "Probinsya is required" }]}>
          <InputSelectField
            name="province"
            bordered={false}
            onChange={(_, options) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  province: options?.value,
                  municipality: null,
                  barangay: null,
                },
              });
              setSelectedProvince(options?.value);
            }}
            options={provinceOptions}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </FormItem>
        <FormItem
          label="Lungsod/Munisipyo"
          name="municipality"
          rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
          <InputSelectField
            key="municipality"
            name="municipality"
            bordered={false}
            onChange={(_, option) => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  municipality: option?.value,
                  barangay: null,
                },
              });
              setSelectedMunicipality(option?.value);
            }}
            disabled={!selectedProvince}
            options={municipalityOptions}
            value={selectedMunicipality || null}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </FormItem>
        <div style={{ display: "flex" }}>
          <FormItem label="Barangay" name="barangay" style={{ marginRight: "1rem", width: "70%" }}>
            {showAddBarangay ? (
              <InputTextFields
                disabled={!selectedMunicipality}
                name="barangay"
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      barangay: e.target.value,
                    },
                  })
                }
                onBlur={async e => {
                  if (e.target.value) {
                    const res = await addBarangay(e.target.value, selectedMunicipality);
                    stateDispatchMain({
                      key: "citizenDetails",
                      payload: {
                        ...state.citizenDetails,
                        barangay: res.id,
                        barangay_name: res.name,
                      },
                    });
                  }
                }}
              />
            ) : (
              <InputSelectField
                name="barangay"
                allowClear
                bordered={false}
                onChange={(_, option) => {
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      barangay: option?.value,
                      barangay_name: option?.name,
                    },
                  });
                }}
                options={barangayOptions}
                disabled={!selectedMunicipality}
                showSearch
                notFoundContent={!isLoading ? "Loading.." : "No Data"}
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            )}
          </FormItem>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "30%",
              textAlign: "center",
            }}>
            <div style={{ margin: "10px 0" }}>
              {showAddBarangay ? (
                <a>
                  <CloseCircleOutlined
                    style={{ transform: "scale(1.5)" }}
                    onClick={() => setShowAddBarangay(curr => !curr)}
                  />
                </a>
              ) : (
                <a>
                  <PlusCircleOutlined
                    style={{ transform: "scale(1.5)" }}
                    onClick={() => setShowAddBarangay(curr => !curr)}
                  />
                </a>
              )}
            </div>
            <div>
              {showAddBarangay ? (
                <p>Go back to list</p>
              ) : (
                <>
                  <p>Add New Barangay</p>
                </>
              )}
            </div>
          </div>
        </div>
        <FormItem label="House No./Purok" name="address" rules={[{ required: true, message: "Address is required" }]}>
          <InputTextFields
            name="address"
            onChange={e =>
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  address: e.target.value,
                },
              })
            }
          />
        </FormItem>
      </div>
    </Wrapper>
  );
};

export default EditForm;
