import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewCitizenContextProvider from "../src/pages/Register/Form/NewCitizenContext";
import SearchProvider from "pages/Search/SearchContext";
import SocialServiceProvider from "pages/SocialService/Provider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <NewCitizenContextProvider>
        <SocialServiceProvider>
          <SearchProvider>
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
          </SearchProvider>
        </SocialServiceProvider>
      </NewCitizenContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
