const { styled } = require("styled-components");
import Background from "assets/bg.png";

export const StyledElement = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 165px;

  @media only screen and (min-width: 768px) {
    background: none;
    padding-top: 0px;
    box-shadow: none;
    margin-top: 0px;
  }
`;

export const MobileContainer = styled.div`
  border-radius: 65px 65px 0 0; /* Top-left and top-right corners only */
  background: #fff;
  height: auto;
  box-shadow: 0px -3px 16px 0px rgba(0, 0, 0, 0.1);
  min-height: calc(100vh - 165px);

  @media only screen and (min-width: 768px) {
    border-radius: initial; /* Reset border-radius */
    background: none;
    box-shadow: none;
    margin-top: 0px;
  }
`;
