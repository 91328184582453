import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterHomePageContainer } from "../components/Register.styled";
import { Button, Form, Input } from "antd";
import styled from "styled-components";
import { NewCitizenContext } from "../Form/NewCitizenContext";
import { verifyCode } from "services/public_registration.service";
import Swal from "sweetalert2";

const StyledFormContainer = styled('div')`
  text-align: center;
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  .text-container {
    margin-top: 16px;
    margin-bottom: 16px;
    width: 100%;
  }
  .ant-input {
    height: 59px;
    border: 2px solid #2D378C;
    border-radius: 10px;
  }
  .ant-btn {
    height: 59px;
    width: 100%;
    background-color: #8C2D71;
    color: #FFFFFF;
    box-shadow: none;
    outline: none;
    border: 0;
    > *  {
      color: #FFFFFF;
    }
  }
  .ant-btn-primary {
    background-color: #2D378C;
  }
`;

const RegisterHomepageCode = () => {
  const navigate = useNavigate();
  const { setCitizenCode } = useContext(NewCitizenContext);

  const onFinish = async values => {
    try {
      await verifyCode(values.code);
      setCitizenCode(values.code);
      navigate("/agreement", { state: { ...values, isPassed: true } })
    } catch (error) {
      Swal.fire({
        title: "Oops!",
        text: "Mali ang iyong nilagay na code",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
  };

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo);
  };

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
      </div>

      <div className="right-side-container">
        <StyledFormContainer>
          <img src="/static/images/logo.png" className="bp-logo" />
          <div className="text-container">
            <p>
              May naibigay ba na <strong>code</strong> sa inyo?
            </p>
          </div>
          <Form
            name="basic"
            wrapperCol={{ span: 24 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="code"
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Magpatuloy
              </Button>
            </Form.Item>

            <Form.Item>
              <Button onClick={() => navigate("/agreement", { state: { isPassed: true } })}>
                Magpatuloy ng walang <strong>&nbsp;code</strong>
              </Button>
            </Form.Item>
          </Form>
        </StyledFormContainer>
      </div>

    </RegisterHomePageContainer>
  );
};

export default RegisterHomepageCode;
