import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Button, Form, Input, Select, message } from "antd";
import { daysOptions, monthsOptions, yearOptions } from "constant/selectConstants";
import moment from "moment";
import IDTable from "./components/IDTable";
import { getCitizensList } from "services/citizen";
import { SearchContext } from "./SearchContext";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1rem;

  .search-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .ant-row {
      margin: 0;
    }

    @media only screen and (min-width: 768px) {
      width: 65vw;
      margin: 0 auto;
    }

    @media only screen and (min-width: 900px) {
      width: 50vw;
      margin: 0 auto;
    }
  }

  .birthdate-container {
    display: flex;
    gap: 0.5rem;
    align-items: flex-start;
    justify-content: space-between;
    margin: 0;

    .ant-row {
      margin-top: -0.5rem;
    }

    .date-select {
      width: 33%;
    }
  }

  .search-btn {
    border-radius: 8px;
    width: 100%;

    &.ant-btn-primary {
      background-color: #ac141e;
      color: #ffffff;
      border-color: #ac141e;

      span {
        color: #ffffff;
      }

      &:hover,
      &:focus {
        background-color: #9a121b;
        border-color: #9a121b;
      }
    }
  }

  .bp-logo {
    width: 5rem;
    margin: 0 auto;
    z-index: 5;

    @media only screen and (min-width: 390px) {
      width: 10rem;
    }

    @media only screen and (min-width: 480px) and (orientation: portrait) {
      width: 8.5rem;
    }

    @media only screen and (min-width: 768px) {
      width: 12.5rem;
      margin: 0 auto;
      margin-top: 2rem;
    }
  }

  .bg {
    position: fixed;
    width: 55vw;
    bottom: 0;

    @media only screen and (max-width: 899px) {
      display: none;
    }
  }
`;
const FormContainer = styled.div`
  .search-by-select {
    flex-direction: column;
  }
  @media only screen and (min-width: 768px) {
    width: 65vw;
    margin: 0 auto;
    .search-by-select {
      flex-direction: row;
    }
  }

  @media only screen and (min-width: 900px) {
    width: 50vw;
    margin: 0 auto;
  }
`;

const LogoutButton = styled(Button)`
  position: absolute;
  top: 15px;
  left: 15px;
  font-size: 12px;
`;
function SearchPage() {
  const { logout } = useContext(SearchContext);
  const [form] = Form.useForm();

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const resendInterval = useRef(null);

  useEffect(() => {
    const year = form?.getFieldValue("year");
    const month = form?.getFieldValue("month");
    const date = form?.getFieldValue("date");

    if (date) {
      const dateOptions = daysOptions(month, year);

      if (!dateOptions.find(d => d.value === date)) {
        form?.setFieldsValue({ date: undefined });
      }
    }
  }, [form?.getFieldValue("month"), form?.getFieldValue("year")]);

  useEffect(() => {
    handleSearch();
  }, [page, pageSize]);

  const handleSearch = useCallback(async () => {
    if (resendInterval.current) {
      clearInterval(resendInterval.current);
    }

    const search = form?.getFieldValue("search");
    let birth_date;
    if (form.getFieldValue("year") && form.getFieldValue("month") && form.getFieldValue("date")) {
      birth_date = moment(
        `${form?.getFieldValue("year")}-${form?.getFieldValue("month")}-${form?.getFieldValue("date")}`,
        "YYYY-MM-DD",
        false,
      ).format("YYYY-MM-DD");
    }
    try {
      setLoading(true);
      await getCitizensList(search, birth_date, page, pageSize).then(data => {
        setData(data?.results || []);
        setTotal(data?.count || 0);
      });
    } catch (error) {
      message.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    page,
    pageSize,
    form?.getFieldValue("search"),
    form?.getFieldValue("year"),
    form?.getFieldValue("month"),
    form?.getFieldValue("date"),
  ]);

  useEffect(() => {
    getCitizensList("", "", page, pageSize).then(data => {
      setData(data?.results || []);
      setTotal(data?.count || 0);
    });
  }, []);

  return (
    <Wrapper>
      <img src="static/images/logo.png" className="bp-logo" />
      <LogoutButton
        onClick={() => {
          logout();
        }}
        size="small">
        Logout
      </LogoutButton>
      <FormContainer>
        <Form form={form} className="search-form" onFinish={handleSearch} layout="vertical">
          <Form.Item label="Search" name="search">
            <Input name="search" />
          </Form.Item>
          <Form.Item label="Birthdate">
            <div className="birthdate-container">
              <Form.Item className="date-select" label="Year" name="year">
                <Select options={yearOptions()} showSearch allowClear onChange={e => setSelectedYear(e)} />
              </Form.Item>
              <Form.Item className="date-select" label="Month" name="month">
                <Select
                  options={monthsOptions()}
                  filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                  showSearch
                  allowClear
                  onChange={e => setSelectedMonth(e)}
                />
              </Form.Item>
              <Form.Item className="date-select" label="Date" name="date">
                <Select
                  options={daysOptions(selectedMonth, selectedYear)}
                  disabled={!selectedYear || !selectedMonth}
                  showSearch
                  allowClear
                />
              </Form.Item>
            </div>
          </Form.Item>

          <Button type="primary" className="search-btn" htmlType="submit">
            Search
          </Button>
          <IDTable
            data={data}
            loading={loading}
            pagination={{
              position: ["bottomRight"],
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              },
              pageSize: pageSize,
              current: page,
              defaultCurrent: page,
              onChange: pg => setPage(pg),
              total,
            }}
            resendInterval={resendInterval}
          />
        </Form>
      </FormContainer>
    </Wrapper>
  );
}

export default SearchPage;
