import { compressImage } from "utils";
import { ROOT_URL } from "../config";

const verifyMobileNumber = async phone_number => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/is_contact_used/?phone_number=${phone_number}`, {
      method: "GET",
    });
    if (response.status === 200) {
      return await response.json();
    }
  } catch (Exception) {
    console.log(Exception);
  }
};

const getBarangayOption = async municipalityID => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/barangay/?municipality=${municipalityID}`, {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getATC = async municipalityID => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/atc/?municipality_id=${municipalityID}`, {
      method: "GET",
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const generateIDCard = async citizen_id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/view_card/?citizen_id=${citizen_id}`, {
      headers: {
        "Content-Type": "image/png",
      },
      method: "GET",
    });

    return await response.blob();
  } catch (Exception) {
    console.error(Exception);
  }
};

const viewIDCard = async citizen_id => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/view_card/?citizen_id=${citizen_id}&view_only=1`, {
      headers: {
        "Content-Type": "image/png",
      },
      method: "GET",
    });

    return await response.blob();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAgency = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/agency/", {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getSector = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/sector/", {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getScopes = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/scope/", {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getAccreditors = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/accreditor/", {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getSocialService = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/service/", {
      method: "GET",
    });

    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const verifyCitizen = async (first_name, last_name, name_extension, birth_date) => {
  try {
    const response = await fetch(
      ROOT_URL +
      `/citizen/is_citizen_existing/?first_name=${first_name}&last_name=${last_name}&name_extension=${name_extension}&birth_date=${birth_date}`,
      {
        method: "GET",
      },
    );
    if (response.status === 200) {
      return await response.json();
    }
  } catch (Exception) {
    console.error(Exception);
  }
};

const sendOTP = async phone_number => {
  let formData = new FormData();
  formData.append("phone_number", phone_number);
  try {
    const response = await fetch(ROOT_URL + "/core/otp/send", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const validateOTP = async (message_id, otp) => {
  let formData = new FormData();
  formData.append("message_id", message_id);
  formData.append("otp", otp);
  try {
    const response = await fetch(ROOT_URL + "/core/otp/validate", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const registerCitizen = async citizenDetails => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(citizenDetails),
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('There was an error');
    }
  } catch (Exception) {
    console.error(Exception);
    throw new Error(Exception);
  }
};

const patchCitizen = async (id, formData) => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/" + id + "/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "PATCH",
      body: JSON.stringify(formData),
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('There was an error');
    }
  } catch (Exception) {
    console.error(Exception);
    throw new Error(Exception);
  }
};

const patchCitizenOrganization = async (id, formData) => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/organization_detail/" + id + "/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "PATCH",
      body: JSON.stringify(formData),
    });
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('There was an error');
    }
  } catch (Exception) {
    console.error(Exception);
    throw new Error(Exception);
  }
};

const registerCitizenSocialService = async socialServiceDetails => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/social_service/", {
      headers: {
        "Content-Type": "application/json; charset=UTF-8",
      },
      method: "POST",
      body: JSON.stringify(socialServiceDetails),
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const uploadCitizenPhoto = async photo => {
  let formData = new FormData();
  const compressedPhoto = await compressImage(photo);
  formData.append("photo", compressedPhoto);
  try {
    const response = await fetch(ROOT_URL + "/citizen/upload_photo", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const addBarangay = async (name, municipalityID) => {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("municipality", municipalityID);
  try {
    const response = await fetch(ROOT_URL + "/citizen/barangay/", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getOrganization = async regionID => {
  try {
    const response = await fetch(ROOT_URL + `/citizen/organization/?region=${regionID}`, {
      method: "GET",
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const getPosition = async () => {
  try {
    const response = await fetch(ROOT_URL + "/citizen/position/", {
      method: "GET",
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const addNewOrganization = async (name, regionID) => {
  let formData = new FormData();
  formData.append("name", name);
  formData.append("region", regionID);
  try {
    const response = await fetch(ROOT_URL + "/citizen/organization", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const addNewPosition = async name => {
  let formData = new FormData();
  formData.append("name", name);
  try {
    const response = await fetch(ROOT_URL + "/citizen/position/", {
      method: "POST",
      body: formData,
    });
    return await response.json();
  } catch (Exception) {
    console.error(Exception);
  }
};

const verifyCode = async code => {
  try {
    const response = await fetch(
      ROOT_URL +
      `/citizen/code/${code}/`,
      {
        method: "GET",
      },
    );
    if (response.status === 200) {
      return await response.json();
    } else {
      throw new Error('Invalid code!');
    }
  } catch (Exception) {
    throw new Error(Exception);
  }
};

export {
  verifyMobileNumber,
  getBarangayOption,
  sendOTP,
  registerCitizen,
  patchCitizen,
  patchCitizenOrganization,
  validateOTP,
  verifyCitizen,
  getATC,
  generateIDCard,
  viewIDCard,
  registerCitizenSocialService,
  uploadCitizenPhoto,
  getAgency,
  getSector,
  getSocialService,
  addBarangay,
  getOrganization,
  getPosition,
  addNewOrganization,
  addNewPosition,
  verifyCode,
  getScopes,
  getAccreditors
};
