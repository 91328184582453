import React, { useContext } from "react";
import { FormItem, InputTextFields, InputSelectField, RadioButton, InputPhoneNumberField } from "../../components/InputField.styled";
import PropTypes from "prop-types";
import { RegisterStepContainer } from "../../components/Register.styled";
import { Radio, Space } from "antd";
import { NewCitizenContext } from "../NewCitizenContext";
import PersonalAddress from "./PersonalAddress";
import { validators } from "utils/validators";

/**
 * @typedef {import("antd").FormInstance} FormInstance
 */
const Organization = ({ children }) => {
  const { stateDispatchMain,
    state,
    accreditorOptions,
    agencyOptions,
    organizationOptions,
    scopeOptions,
    sectorOptions,
    positionOptions,
    formatPhoneNumber,
    parsePhoneNumber, formRef
  } = useContext(NewCitizenContext);
  const { citizenDetails, organizationDetails } = state;

  /** @type {FormInstance}*/
  const form = formRef?.current;

  if (!form) {
    return null;
  }

  return (
    <RegisterStepContainer>
      <FormItem
        style={{ width: "100%" }}
        label="Sector"
        name="sector"
        rules={[{ required: true, message: "Sector is required" }]}>
        <InputSelectField
          name="sector"
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: "organizationDetails",
              payload: {
                ...state.organizationDetails,
                sector: option?.value,
              },
            });
            form.setFieldsValue({ sector: option });
          }}
          options={sectorOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>

      {
        organizationDetails.sector === 1 ?
          (

            <FormItem
              style={{ width: "100%" }}
              label="Agency"
              name="agency"
              rules={[{ required: true, message: "Agency is required" }]}>
              <InputSelectField
                name="agency"
                bordered={false}
                onChange={(_, option) => {
                  stateDispatchMain({
                    key: "organizationDetails",
                    payload: {
                      ...state.organizationDetails,
                      agency: option?.value,
                    },
                  });
                  form.setFieldsValue({ agency: option });
                }}
                options={agencyOptions}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
          )
          : null
      }

      {
        [2, 3, 5].includes(organizationDetails.sector) ? <FormItem
          label="Organization"
          name="sector_openfield"
          rules={[{ required: true, message: "Organization is required" }]}
        >
          <InputTextFields
            name="sector_openfield"
            value={organizationDetails.sector_openfield}
            onChange={e =>
              stateDispatchMain({
                key: "organizationDetails",
                payload: {
                  ...state.organizationDetails,
                  sector_openfield: e.target.value,
                },
              })
            }
          />
        </FormItem> : null
      }

      {
        organizationDetails.sector === 4 ?
          (

            <FormItem
              style={{ width: "100%" }}
              label="Organization"
              name="organization"
              rules={[{ required: true, message: "Organization is required" }]}>
              <InputSelectField
                name="organization"
                bordered={false}
                onChange={(_, option) => {
                  stateDispatchMain({
                    key: "organizationDetails",
                    payload: {
                      ...state.organizationDetails,
                      organization: option?.value,
                    },
                  });
                  form.setFieldsValue({ organization: option });
                }}
                options={organizationOptions}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              />
            </FormItem>
          )
          : null
      }
      <div style={{ width: "100%" }}>
        <FormItem name="position" rules={[{ required: true, message: "Position is required" }]}>
          <h4 style={{ fontWeight: "bold", margin: "1rem 0" }}>Position</h4>
          <Radio.Group
            name="position"
            onChange={e => {
              stateDispatchMain({
                key: "citizenDetails",
                payload: {
                  ...state.citizenDetails,
                  position: e.target.value,
                },
              });
              form.setFieldsValue({ position: e.target.value });
            }}
            value={state.citizenDetails.position}>
            <Space direction="vertical">
              {positionOptions?.map(position => {
                return (
                  <RadioButton
                    key={position?.value}
                    value={position?.value}>
                    {position?.label}
                  </RadioButton>
                );
              })}
            </Space>
          </Radio.Group>
        </FormItem>
        {
          citizenDetails.position === 11 ? (
            <FormItem label="Other Position" name="position_others">
              <InputTextFields
                name="position_others"
                value={citizenDetails.position_others}
                onChange={e =>
                  stateDispatchMain({
                    key: "citizenDetails",
                    payload: {
                      ...state.citizenDetails,
                      position_others: e.target.value,
                    },
                  })
                }
              />
            </FormItem>
          ) : null
        }
      </div>

      <h4 style={{ fontWeight: "bold", margin: "1rem 0" }}>Scope</h4>
      <FormItem
        style={{ width: "100%" }}
        label="Sakop ng Organisasyon"
        name="organization_scope"
        rules={[{ required: true, message: "Scope is required" }]}>
        <InputSelectField
          name="organization_scope"
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: "organizationDetails",
              payload: {
                ...organizationDetails,
                scope: option?.value,
              },
            });
            form.setFieldsValue({ scope: option });
          }}
          options={scopeOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Tinatayang bilang ng mga Miyembro"
        name="organization_member_count"
        rules={[
          {
            required: true,
            message: "Member count is required",
          },
          validators.positiveWholeNumber
        ]}
      >
        <InputTextFields
          name="organization_member_count"
          type="number"
          required
          value={organizationDetails.member_count}
          onChange={e =>
            stateDispatchMain({
              key: "organizationDetails",
              payload: {
                ...organizationDetails,
                member_count: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <h4 style={{ fontWeight: "bold", margin: "1rem 0" }}>Organization Address</h4>
      <PersonalAddress
        stateKey="organizationDetails"
        fieldNamePrefix="organization_"
        isFormOnly
      />

      <h4 style={{ fontWeight: "bold", margin: "1rem 0" }}>President</h4>

      <FormItem
        label="Presidente ng Organisasyon"
        name="organization_president"
        rules={[{ required: true, message: "President is required" }]}
      >
        <InputTextFields
          name="organization_president"
          value={organizationDetails.president}
          required
          onChange={e =>
            stateDispatchMain({
              key: "organizationDetails",
              payload: {
                ...organizationDetails,
                president: e.target.value,
              },
            })
          }
        />
      </FormItem>
      <FormItem
        label="Contact Number ng Presidente"
        name="organization_president_phone_number"
        validateTrigger={["onBlur"]}
        rules={[
          { required: true, message: "Mobile number is required" },
          {
            validator: async (_, value) => {
              if (value.toString().length < 10) {
                return Promise.reject("Incorrect mobile number format");
              } else {
                return Promise.resolve();
              }
            },
            message: "Incorrect mobile number format",
          },
        ]}>
        <InputPhoneNumberField
          name="phone_number"
          autoComplete="off"
          formatter={formatPhoneNumber}
          parser={parsePhoneNumber}
          onChange={value => {
            stateDispatchMain({
              key: "organizationDetails",
              payload: {
                ...state.organizationDetails,
                phone_number: value,
              },
            });
          }}
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          defaultValue={63}
          maxLength={12}
          minLength={12}
        />
      </FormItem>
      <FormItem
        style={{ width: "100%" }}
        label="Nakarehistro ba ang inyong organisasyon?"
        name="organization_is_registered">
        <InputSelectField
          bordered={false}
          options={[
            {
              label: "Yes",
              value: true
            },
            {
              label: "No",
              value: false
            }
          ]}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      {
        form.getFieldValue('organization_is_registered') ?
          <FormItem
            style={{ width: "100%" }}
            label="If YES, choose the issuer of Accreditation:"
            name="organization_accreditor"
            rules={[{ required: true, message: "Scope is required" }]}>
            <InputSelectField
              name="organization_accreditor"
              bordered={false}
              onChange={(_, option) => {
                stateDispatchMain({
                  key: "organizationDetails",
                  payload: {
                    ...organizationDetails,
                    accreditor: option?.value,
                  },
                });
                form.setFieldsValue({ accreditor: option });
              }}
              options={accreditorOptions}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            />
          </FormItem>
          : null
      }
      {children}
    </RegisterStepContainer>
  );
};

Organization.propTypes = {
  formData: PropTypes.any,
  onInputChange: PropTypes.func,
  errors: PropTypes.any,
};

export default Organization;
