import MainLayout from "layouts/MainLayout/MainLayout";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSocialServiceContext } from "../Context";
import { getCitizen, getCitizenByCardNumber } from "services/citizen";
import { styled } from "styled-components";
import Background from "assets/bg.png";
import { Divider } from "antd";
import Title from "antd/lib/typography/Title";
import RedeemForm from "../components/RedeemForm";
import { MobileContainer } from "../components/reusables";

const CitizenProfileContainer = styled(MobileContainer)``;
const StyledElement = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: contain;
  padding-top: 165px;

  @media only screen and (min-width: 768px) {
    background: none;
    padding-top: 0px;
    box-shadow: none;
    margin-top: 0px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 0px !important;

  @media only screen and (min-width: 700px) {
    border-top: 1px solid white !important;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0px !important;
`;

const Redeeem = () => {
  const { id } = useParams();
  const { setCitizen, citizen } = useSocialServiceContext();

  useEffect(() => {
    if (!citizen) {
      if (/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(id)) {
        getCitizen(id)
          .then(citizen => {
            setCitizen(citizen);
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        getCitizenByCardNumber(id)
          .then(citizen => {
            setCitizen(citizen);
          })
          .catch(error => {
            console.log(error);
          });
      }
    }
  }, []);

  if (!id) {
    throw Error("No ID provided");
  }

  return (
    <StyledElement>
      <CitizenProfileContainer>
        <MainLayout>
          <StyledTitle level={3}>Social Service</StyledTitle>
          <StyledDivider />
          <RedeemForm />
        </MainLayout>
      </CitizenProfileContainer>
    </StyledElement>
  );
};

export default Redeeem;
