import React, { useContext, useState } from "react";
import { UploadImageAvatar, UploadButton, UploadImageStepContainer } from "../../components/Register.styled";
import defaultAvatar from "../../../../assets/default_avatar.png";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload, Spin } from "antd";
import { NewCitizenContext } from "../NewCitizenContext";
import { uploadCitizenPhoto } from "../../../../services/public_registration.service";
import ImageCropperDialog from "components/ImageCropper";

const UploadImage = () => {
  const { state, stateDispatchMain } = useContext(NewCitizenContext);
  const [imageCropper, setImageCropper] = useState({
    isOpen: false,
    imageFile: null
  })

  const [image, setImage] = useState(state.citizenDetails?.profile || '');
  const [isLoading, setIsLoading] = useState(false);

  const validImageFormats = ["image/jpeg", "image/jpg", "image/png", "image/gif"];

  const onUpload = async (file) => {
    const uploadedMedia = await uploadCitizenPhoto(file);

    stateDispatchMain({
      key: "citizenDetails",
      payload: {
        ...state.citizenDetails,
        profile: uploadedMedia.url,
      },
    });
    setIsLoading(false);
    setImage(URL.createObjectURL(file));
    setImageCropper({
      isOpen: false,
      imageFile: null
    })
  }

  const onSelect = async ({ file, onError }) => {
    setIsLoading(true);
    if (!validImageFormats.includes(file?.type)) {
      setTimeout(() => {
        onError("error");
      }, 100);
      return;
    }

    setImageCropper({
      isOpen: true,
      imageFile: file
    })
  };

  const props = {
    accept: "image/*",
    name: name,
    customRequest: onSelect,
  };

  return (
    <UploadImageStepContainer>
      <p style={{ padding: "0 10px" }}>
        Mangyaring kumuha ng malinaw na larawan ng iyong mukha. Tiyaking buong mukha mo ay malinaw na makikita sa
        larawan.
      </p>
      <div style={{ margin: "30px 0" }}>
        {isLoading ? <Spin /> : <UploadImageAvatar src={image || defaultAvatar} />}
      </div>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
        <Upload {...props} showUploadList={false}>
          <UploadButton
            type="dashed"
            icon={<CloudUploadOutlined style={{ fontSize: "30px", fontWeight: "bold", marginBottom: "10px" }} />}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              padding: "50px 25px",
              borderRadius: "20px",
              borderColor: "#262261",
            }}>
            Upload File
          </UploadButton>
        </Upload>
      </div>
      <ImageCropperDialog
        open={imageCropper.isOpen}
        onClose={() => {
          setImageCropper({
            isOpen: false,
            imageFile: null
          });
          setIsLoading(false);
        }}
        imageFile={imageCropper.imageFile}
        getImage={onUpload}
      />
    </UploadImageStepContainer>
  );
};

export default UploadImage;
