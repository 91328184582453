import React, { useContext, useState } from "react";
import { Alert, Form } from "antd";
import { FormItem, InputTextFields } from "pages/Register/components/InputField.styled";
import StyledButton from "components/button/Button";
import styled from "styled-components";
import Background from "assets/bg.png";
import { searchLoginService } from "services/citizen";
import { SearchContext } from "pages/Search/SearchContext";

const Container = styled.div`
  border-top: 15px solid #dd4542;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom left;
  height: 100vh;
  position: absolute;
  z-index: 1;
  height: 100vh;
  width: 100%;
  .ant-form {
    padding: 22px;
  }
`;
const FormContainer = styled.div`
  width: 500px;
  max-width: 100%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  .ant-form {
    margin-right: auto;
    margin-left: auto;
  }
`;
const LogoContainer = styled.div`
  text-align: center;
`;
const Logo = styled.img`
  background-image: url(/static/images/UNLAD_PINAS_SHIRT4x.png);
  background-repeat: no-repeat;
  background-size: contain;
  width: 150px;
  height: auto;
  margin: 30px auto;
  text-align: center;
`;

const LoginForm = () => {
  const [form] = Form.useForm();
  const { login } = useContext(SearchContext);
  const [error, setError] = useState("");

  const onFinish = async values => {
    try {
      const response = await searchLoginService(values.username, values.password);
      login(response);
      window.location.reload();
    } catch (error) {
      setError(error?.detail || "User not found");
      console.warn("error", error);
    }
  };

  const onFinishFailed = errorInfo => {
    console.error("Failed:", errorInfo);
  };

  return (
    <Container>
      <LogoContainer>
        <Logo src="/static/images/UNLAD_PINAS_SHIRT4x.png" alt="bp-logo" />
      </LogoContainer>
      <FormContainer>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            minWidth: "90%",
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off">
          <FormItem
            name="username"
            label="Username"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}>
            <InputTextFields
              name="username"
              onChange={e =>
                form.setFieldsValue(values => ({
                  ...values,
                  username: e.target.value,
                }))
              }
            />
          </FormItem>

          <FormItem
            wrapperCol={{
              span: 24,
            }}
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}>
            <InputTextFields
              style={{ color: "#000000", fontSize: "16px" }}
              type="password"
              name="password"
              onChange={e =>
                form.setFieldsValue(values => ({
                  ...values,
                  password: e.target.value,
                }))
              }
            />
          </FormItem>

          {error && (
            <Form.Item>
              <Alert description={error} type="error" closable onClose={() => setError("")} />
            </Form.Item>
          )}

          <Form.Item>
            <StyledButton type="primary" htmlType="submit" block>
              Login
            </StyledButton>
          </Form.Item>
        </Form>
      </FormContainer>
    </Container>
  );
};
export default LoginForm;
