import React, { useContext } from "react";
import { NewCitizenContext } from "../NewCitizenContext";
import { DownloadIdStepContainer } from "pages/Register/components/Register.styled";

const IDCard = () => {
  const { IDCard, downloadCitizenID } = useContext(NewCitizenContext);

  const handleRegisterAgain = () => {
    window.location.href = "/";
  };

  return (
    <DownloadIdStepContainer>
      <div className="content-container">
        <div className="text-content">
          <h2 className="header-text">Maraming Salamat sa pag-Rehistro sa Bagong Pilipinas!</h2>
          <p className="help-text">
            Narito ang iyong ID. Click the button below to <span className="help-text-download">download.</span>
          </p>
        </div>
        <img src={IDCard.url} className="id" />
        <button onClick={downloadCitizenID} className="download-id-btn" disabled={!IDCard.url}>
          Download
        </button>
        <button onClick={handleRegisterAgain} className="register-again-btn">
          Magparehistro muli
        </button>
      </div>
    </DownloadIdStepContainer>
  );
};

export default IDCard;
