export const validators = {
  name: {
    validator: async (_, value) => {
      const pattern = /[\u2100-\u27BF\uE000-\uF8FF,/#!$%^&*;:{}=_`~()]/u;
      if (value && pattern.test(value)) {
        return Promise.reject("Invalid name");
      } else if (value && !value.trim()) {
        return Promise.reject("Empty space not allowed");
      } else if (value) {
        return Promise.resolve();
      }
    },
    message: "Invalid name",
  },
  address: {
    validator: async (_, value) => {
      if (value && !value.trim()) {
        return Promise.reject("Empty space not allowed");
      } else if (value) {
        return Promise.resolve();
      }
    },
    message: "Invalid address",
  },
  positiveWholeNumber: {
    validator: (_, value) => {
      if (!value || (Number.isInteger(Number(value)) && Number(value) > 0)) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Please enter a positive whole number'));
    },
    message: 'Invalid positive whole number'
  }
}