import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormItem, InputTextFields, InputSelectField } from "../../components/InputField.styled";

import allMunicipalities from "jsonLib/allMunicipalities.json";
import { RegisterStepContainer } from "../../components/Register.styled";
import { NewCitizenContext } from "../NewCitizenContext";
import { validators } from "utils/validators";
import { getBarangayOption } from "services/public_registration.service";

const PersonalAddress = ({ children,
  stateKey = 'citizenDetails',
  fieldNamePrefix = '',
  isFormOnly = false
}) => {
  const {
    provinceOptions,
    stateDispatchMain,
    state,
    setSelectedRegion,
    formRef
  } = useContext(NewCitizenContext);
  const form = formRef?.current;

  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [barangayOptions, setBarangayOptions] = useState();

  useEffect(() => {
    if (state[stateKey].province) {
      const filteredMunicipality = allMunicipalities
        .filter(municipality => municipality?.fields?.province === state[stateKey].province)
        .map(municipality => ({
          ...municipality,
          label: municipality?.fields.name,
          value: municipality?.pk,
        }));
      setMunicipalityOptions(filteredMunicipality);
    }
  }, [state[stateKey].province]);

  useEffect(() => {
    if (state[stateKey].municipality) {
      setBarangayOptions([]);
      const fetchBarangay = async () => {
        const barangayResponse = await getBarangayOption(state[stateKey].municipality);
        const filteredBarangayOptions = barangayResponse.results.map(barangay => ({
          ...barangay,
          label: barangay?.name,
          value: barangay?.id,
        }));
        setBarangayOptions(filteredBarangayOptions);
      };

      fetchBarangay().catch(console.error);
    }
  }, [state[stateKey].municipality]);

  if (!form) {
    return null;
  }

  const renderForm = (
    <>
      <FormItem label="Probinsya" name={`${fieldNamePrefix}province`} rules={[{ required: true, message: "Probinsya is required" }]}>
        <InputSelectField
          name={`${fieldNamePrefix}province`}
          bordered={false}
          onChange={(_, options) => {
            stateDispatchMain({
              key: stateKey,
              payload: {
                ...state[stateKey],
                province: options?.value,
                municipality: null,
                barangay: null,
              },
            });
            if (!fieldNamePrefix) {
              setSelectedRegion(options?.fields.region);
            }
            form.setFieldsValue({
              [`${fieldNamePrefix}province`]: options?.value,
              [`${fieldNamePrefix}municipality`]: undefined,
              [`${fieldNamePrefix}barangay`]: undefined,
            });
          }}
          value={state[stateKey].province}
          options={provinceOptions}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <FormItem
        label="Lungsod/Munisipyo"
        name={`${fieldNamePrefix}municipality`}
        rules={[{ required: true, message: "Lungsod/Munisipyo is required" }]}>
        <InputSelectField
          ref
          key="municipality"
          name={`${fieldNamePrefix}municipality`}
          bordered={false}
          onChange={(_, option) => {
            stateDispatchMain({
              key: stateKey,
              payload: {
                ...state[stateKey],
                municipality: option?.value,
                barangay: null,
              },
            });

            form.setFieldsValue({
              [`${fieldNamePrefix}municipality`]: option?.value,
              [`${fieldNamePrefix}barangay`]: undefined
            });
          }}
          disabled={!state[stateKey].province}
          options={municipalityOptions}
          value={state[stateKey].municipality}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => {
            return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
          }}
        />
      </FormItem>
      <div style={{ display: "flex" }}>
        <FormItem
          label="Barangay"
          name={`${fieldNamePrefix}barangay`}
          style={{ marginRight: "1rem", width: "70%" }}
          rules={[{ required: true, message: "Barangay is required" }]}
        >
          <InputSelectField
            name={`${fieldNamePrefix}barangay`}
            allowClear
            bordered={false}
            onChange={(_, option) => {
              stateDispatchMain({
                key: stateKey,
                payload: {
                  ...state[stateKey],
                  barangay: option?.value,
                },
              });
              form.setFieldsValue({ [`${fieldNamePrefix}barangay`]: option });
            }}
            value={state[stateKey].barangay}
            options={barangayOptions}
            disabled={!state[stateKey].municipality}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => {
              return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
          />
        </FormItem>
      </div>
      <FormItem label="House No./Purok" name={`${fieldNamePrefix}address`} rules={[{ required: true, message: "Address is required" }, validators.address]}>
        <InputTextFields
          name={`${fieldNamePrefix}address`}
          onChange={e =>
            stateDispatchMain({
              key: stateKey,
              payload: {
                ...state[stateKey],
                address: e.target.value,
              },
            })
          }
        />
      </FormItem>
    </>
  );

  if (isFormOnly) {
    return renderForm;
  }

  return (
    <RegisterStepContainer>
      {renderForm}
      {children}
    </RegisterStepContainer>
  );
};

PersonalAddress.propTypes = {
  formData: PropTypes.any,
  onInputChange: PropTypes.func,
  errors: PropTypes.any,
};

export default PersonalAddress;
