import React, { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "./GlobalStyles";
import { Spin } from "antd";
import NewCitizen from "./pages/Register/Form";
import "antd/dist/antd.min.css";
import RegisterHomepage from "./pages/Register/Homepage/RegisterHomePage";
import TermsAndConditions from "pages/Register/Form/steps/TermsAndCondition";
import { NewCitizenContext } from "pages/Register/Form/NewCitizenContext";
import Search from "pages/Search";
import QrScanPage from "pages/QRScan/QrScanPage";
import CitizenDetail from "pages/SocialService/CitizenDetail/CitizenDetail";
import Redeem from "pages/SocialService/Redeem";
import RegisterHomepageCode from "pages/Register/Homepage/RegisterHomePageCode";

function App() {
  const { agreement } = useContext(NewCitizenContext);

  return (
    <>
      <GlobalStyle />
      <React.Suspense fallback={<Spin />}>
        <Routes>
          <Route path="/" element={<RegisterHomepage />} />
          {agreement && <Route exact path="/register" element={<NewCitizen />} />}
          <Route exact path="/user-code" element={<RegisterHomepageCode />} />
          <Route exact path="/agreement" element={<TermsAndConditions />} />
          <Route path="*" element={<RegisterHomepage />} />
          <Route exact path="/search" element={<Search />} />
          <Route exact path="/scan" element={<QrScanPage />} />
          <Route exact path="/citizen/:id" element={<CitizenDetail />} />
          <Route exact path="/citizen/:id/redeem" element={<Redeem />} />
        </Routes>
      </React.Suspense>
    </>
  );
}

export default App;
