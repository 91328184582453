import React from "react";
import { useNavigate } from "react-router-dom";
import { RegisterHomePageContainer } from "../components/Register.styled";
import { Button } from "antd";

const RegisterHomepage = () => {
  const navigate = useNavigate();

  return (
    <RegisterHomePageContainer>
      <div className="left-side-container">
      </div>


    <div className="right-side-container">
        <img src="/static/images/UNLAD_PINAS_SHIRT4x.png" className="bp-logo" />
      <div className="text-container">
        <h2>
          Mag-rehistro na para sa <strong>United National Leaders for Advancement and Democracy</strong>
        </h2>
      </div>
        <div className="button-container">
          <Button className="register-btn" onClick={() => navigate("/user-code")} type='link'>
            Register
            <img src="/static/images/arrow-right.png" className="register-btn-arrow" />
          </Button>
        </div>
      </div>

    </RegisterHomePageContainer>
  );
};

export default RegisterHomepage;
