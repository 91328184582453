import React, { useState, useEffect, useReducer, createContext, useCallback, useRef } from "react";
import genderOptions from "../../../jsonLib/gender_options.json";
import allProvince from "../../../jsonLib/allProvince.json";
import {
  getAgency,
  verifyCitizen,
  sendOTP,
  validateOTP,
  uploadCitizenPhoto,
  registerCitizen,
  generateIDCard,
  getSector,
  getScopes,
  getAccreditors,
  patchCitizen,
  patchCitizenOrganization,
} from "../../../services/public_registration.service";
import Swal from "sweetalert2";
import moment from "moment";
import { getOrganization, getPosition, verifyMobileNumber } from "services/public_registration.service";

export const NewCitizenContext = createContext({});
/**
 * @typedef {import("antd").FormInstance} FormInstance
 */

const defaultState = {
  citizenDetails: {
    address: "",
    barangay: null,
    birthdate_day: "",
    birthdate_month: "",
    birthdate_year: "",
    email: "",
    fb_profile_link: '',
    first_name: "",
    gender: null,
    id: null,
    is_verified: false,
    is_voter: null,
    last_name: "",
    middle_name: "",
    municipality: null,
    name_extension: null,
    phone_number: "",
    position: null,
    position_others: null,
    principal: "",
    profession: null,
    profile: "",
    province: null,
  },
  socialServiceDetails: {
    customer: null,
    service: null,
    status: "pending",
    value_requested: "0",
    has_representative: null,
    other_details: {},
  },
  organizationDetails: {
    accreditor: null, // number; pk
    address: "",
    agency: null, // number; pk
    barangay: null, // number; pk
    id: null,
    member_count: null, // number
    municipality: null, // number; pk
    organization: null, // number; pk
    organization_openfield: null,
    phone_number: "",
    president: "",
    scope: null, // number; pk
    sector: null, // number; pk
  }
};

const defaultStateFn = key => {
  return key ? defaultState[key] : defaultState;
};

const stateDispatchReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_CITIZEN_DETAILS":
      return {
        ...state,
        ...action.payload,
      };
    case "RESET":
      return defaultState();

    default: {
      if (action.key) {
        if (typeof action.payload === "object") {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              ...action.payload,
            },
          };
        }

        return {
          ...state,
          [action.key]: action.payload,
        };
      }

      return state;
    }
  }
};

const NewCitizenProvider = ({ children }) => {
  const [state, stateDispatch] = useReducer(stateDispatchReducer, defaultStateFn());

  const [provinceOptions, setProvinceOptions] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [agencyOptions, setAgencyOptions] = useState();
  const [sectorOptions, setSectorOptions] = useState();
  const [selectedSocialService, setSelectedSocialService] = useState();
  const [hasRepresentative, setHasRepresentative] = useState();
  const [messageID, setMessageID] = useState();
  const [otp, setOTP] = useState();
  const [IDCard, setIDCard] = useState();
  const [agreement, setAgreement] = useState(false);
  const [organizationOptions, setOrganizationOptions] = useState();
  const [positionOptions, setPositionOptions] = useState();
  const [scopeOptions, setScopeOptions] = useState([]);
  const [accreditorOptions, setAccreditorOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState();
  const [citizenCode, setCitizenCode] = useState(null);

  const formRef = useRef(null);
  /** @type {FormInstance}*/
  const form = formRef.current;

  function formatPhoneNumber(value) {
    // format the input value as a Philippine phone number
    if (!value) return "";
    value = value.toString();
    if (value.startsWith("0")) return value.replace(/^0/, "63");
    if (value.startsWith("63")) return value;

    // setVal(`63${value}`);
    return `63${value}`;
  }

  function parsePhoneNumber(value) {
    // parse the input value as a number
    if (!value) return 0;
    return value.toString().replace(/^(0|63)/, "");
  }

  const representativeOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  useEffect(() => {
    const filteredProvince = allProvince.map(province => ({
      ...province,
      label: province?.fields.name,
      value: province?.pk,
    }));

    setProvinceOptions(filteredProvince);
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      const fetchOrganization = async () => {
        const organizationOption = await getOrganization(selectedRegion);
        const filteredOrganizationOption = organizationOption.results.map(organization => ({
          ...organization,
          label: organization?.name,
          value: organization.id,
        }));
        setOrganizationOptions(filteredOrganizationOption);
      };
      fetchOrganization().catch(console.error);
    }
  }, [selectedRegion]);

  useEffect(() => {
    const fetchPosition = async () => {
      const positionOption = await getPosition();
      if (positionOption?.results?.length) {
        const filteredPositionOption = positionOption.results.map(position => ({
          ...position,
          label: position?.name,
          value: position.id,
        }));
        setPositionOptions(filteredPositionOption);
      }
    };
    fetchPosition().catch(console.error);
  }, []);

  const loadData = useCallback(async () => {
    try {
      const defaultValues = {};
      const agencies = await getAgency();
      if (agencies?.results?.length) {
        const filteredAgencyOptions = agencies?.results.map(agency => ({
          ...agency,
          label: agency?.name,
          value: agency?.id,
        }));
        setAgencyOptions(filteredAgencyOptions);
        defaultValues.agency = filteredAgencyOptions?.[0]?.value || null;
      }

      const sectors = await getSector();
      if (sectors?.results?.length) {
        const filteredSectorOptions = sectors.results.map(sector => ({
          ...sector,
          label: sector?.name,
          value: sector?.id,
        }));
        defaultValues.sector = filteredSectorOptions?.[0]?.value || null;
        setSectorOptions(filteredSectorOptions);
      }

      const scopes = await getScopes();
      if (scopes?.results?.length) {
        const filteredScopeOptions = scopes.results.map(option => ({
          ...option,
          label: option?.name,
          value: option?.id,
        }));
        setScopeOptions(filteredScopeOptions);
      }

      const accreditors = await getAccreditors();
      if (accreditors?.results?.length) {
        const filteredAccreditorOptions = accreditors.results.map(option => ({
          ...option,
          label: option?.name,
          value: option?.id,
        }));
        setAccreditorOptions(filteredAccreditorOptions);
      }

      if (Object.values(defaultValues).some((val) => val)) {
        stateDispatch({
          key: "organizationDetails",
          payload: {
            ...state.organizationDetails,
            defaultValues,
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  const setExistingCitizenDetails = (citizen) => {
    const updateCitizen = { ...citizen };
    const organizationDetails = citizen.organization_detail;
    delete updateCitizen.organization_detail;
    delete updateCitizen.organization;

    const region = provinceOptions.find((provinceOption) => provinceOption.value === citizen.municipality.province.id)?.fields?.region;
    setSelectedRegion(region);

    stateDispatch({
      key: "citizenDetails",
      payload: {
        ...state.citizenDetails,
        ...updateCitizen,
        barangay: citizen.barangay.id,
        gender: citizen.gender.id,
        province: citizen.municipality.province.id,
        municipality: citizen.municipality.id,
        position: citizen.position.id,
      },
    });

    stateDispatch({
      key: "organizationDetails",
      payload: {
        ...state.organizationDetails,
        ...organizationDetails,
        organization: organizationDetails?.organization?.id || null,
        barangay: organizationDetails?.barangay?.id || null,
        province: organizationDetails?.municipality?.province?.id || citizen.municipality.province.id || null,
        municipality: organizationDetails?.municipality?.id || null,
        sector: organizationDetails?.sector?.id || null,
        agency: organizationDetails?.agency?.id || null,
        scope: organizationDetails?.scope?.id || null,
        accreditor: organizationDetails?.accreditor?.id || null,
      },
    });
    form.setFieldsValue({
      // citizen
      province: citizen.municipality.province.id,
      municipality: citizen.municipality.id,
      barangay: citizen.barangay.id,
      address: citizen.address,
      position: citizen.position.id,
      // organization
      sector: organizationDetails?.sector?.id || null,
      agency: organizationDetails?.agency?.id || null,
      organization: organizationDetails?.organization?.id || null,
      organization_scope: organizationDetails?.scope?.id || null,
      organization_member_count: organizationDetails?.member_count,
      organization_president: organizationDetails?.president,
      organization_president_phone_number: organizationDetails?.phone_number,
      organization_accreditor: organizationDetails?.accreditor?.id || null,
      organization_openfield: organizationDetails?.organization_openfield || null,
      organization_province: organizationDetails?.municipality?.province?.id || citizen.municipality.province.id || null,
      organization_municipality: organizationDetails?.municipality?.id || null,
      organization_barangay: organizationDetails?.barangay?.id || null,
      organization_address: organizationDetails?.address,
    })
  }

  const checkUserExist = async () => {
    if (state.citizenDetails.phone_number) {
      const isMobileNumberValid = await verifyMobileNumber(state.citizenDetails.phone_number);
      const existingCitizen = await verifyCitizen(
        state.citizenDetails.first_name,
        state.citizenDetails.last_name,
        state.citizenDetails.name_extension,
        moment(
          `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
          "YYYY-MM-DD",
          false,
        ).format("YYYY-MM-DD"),
      );
      if (!isMobileNumberValid) {
        if (existingCitizen?.card_number) {
          const action = await Swal.fire({
            title: "Oops!",
            text: "Mayroon ka nang record.",
            allowOutsideClick: true,
            showCancelButton: true,
            cancelButtonText: "Exit",
            showConfirmButton: true,
            confirmButtonText: "Update existing record?",
            width: "80%",
          });
          if (action.isConfirmed) {
            setExistingCitizenDetails(existingCitizen);
          } else {
            window.location.href = "/";
          }
          return action.isConfirmed;
        }
      } else {
        Swal.fire({
          title: "Oops!",
          text: "Ang numero na ito ay ginamit na ng tatlong beses. Mangyaring maglagay ng wastong Mobile Number.",
          allowOutsideClick: true,
          showCancelButton: true,
          cancelButtonText: "Exit",
          showConfirmButton: false,
          width: "80%",
        });
        return false;
      }
    }
  };

  const sendOTPMobile = async () => {
    const otp = await sendOTP(state.citizenDetails.phone_number);
    setMessageID(otp?.message_id);
  };

  const verifyOTP = async () => {
    const isValidOTP = await validateOTP(messageID, otp);
    if (!isValidOTP || otp.length < 6 || otp === undefined) {
      Swal.fire({
        title: "Oops!",
        text: "Mali ang iyong nilagay na OTP",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
    }
    return isValidOTP;
  };

  const uploadPhoto = async () => {
    const isUpload = await uploadCitizenPhoto(state.citizenDetails.profile);
    return isUpload;
  };

  const transformCitizenDetailsToParams = () => {
    const citizenDetailsToParam = { ...state.citizenDetails };
    delete citizenDetailsToParam.birthdate_year;
    delete citizenDetailsToParam.birthdate_month;
    delete citizenDetailsToParam.birthdate_day;

    citizenDetailsToParam.birth_date = moment(
      `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
      "YYYY-MM-DD",
      false,
    ).format("YYYY-MM-DD");

    if (citizenCode) {
      citizenDetailsToParam.code = citizenCode;
    }
    // should not be empty string params; change to `null`
    ['name_extension'].forEach((fieldName) => {
      if (!citizenDetailsToParam?.[fieldName]) {
        citizenDetailsToParam[fieldName] = null
      }
    })

    return citizenDetailsToParam;
  };

  const registerCitizenDetails = async () => {
    if (state.citizenDetails.profile) {
      try {
        const citizenFormData = transformCitizenDetailsToParams();
        const formData = {
          citizen: citizenFormData,
          organization_details: state.organizationDetails
        }
        let citizenId = citizenFormData?.id || null;
        if (citizenId) {
          // update
          await patchCitizen(citizenId, formData.citizen);
          await patchCitizenOrganization(state.organizationDetails.id, formData.organization_details);
        } else {
          // create
          const register = await registerCitizen(formData);
          citizenId = register.id;
        }

        const res = await generateIDCard(citizenId);
        const filename = `${citizenId}.png`;
        setIDCard({ url: URL.createObjectURL(res), filename });
        return true;
      } catch (error) {
        console.error(error);
        Swal.fire({
          title: "Oops!",
          text: "There was an error.",
          allowOutsideClick: true,
          showCancelButton: true,
          cancelButtonText: "Exit",
          showConfirmButton: false,
          width: "80%",
        });
        return false;
      }
    } else {
      Swal.fire({
        title: "Oops!",
        text: "Kinakailangan mag-upload ng iyong litrato",
        allowOutsideClick: true,
        showCancelButton: true,
        cancelButtonText: "Exit",
        showConfirmButton: false,
        width: "80%",
      });
      return false;
    }
  };

  const downloadCitizenID = () => {
    const link = document.createElement("a");
    link.href = IDCard.url;
    link.download = IDCard.filename;
    link.click();
  };

  const payload = {
    IDCard,
    accreditorOptions,
    agencyOptions,
    agreement,
    checkUserExist,
    downloadCitizenID,
    formRef,
    formatPhoneNumber,
    genderOptions,
    hasRepresentative,
    messageID,
    organizationOptions,
    parsePhoneNumber,
    positionOptions,
    provinceOptions,
    registerCitizenDetails,
    representativeOptions,
    scopeOptions,
    sectorOptions,
    selectedProvince,
    selectedRegion,
    selectedSocialService,
    sendOTPMobile,
    setAgreement,
    setCitizenCode,
    setHasRepresentative,
    setOTP,
    setSelectedProvince,
    setSelectedRegion,
    setSelectedSocialService,
    state,
    stateDispatchMain: stateDispatch,
    uploadPhoto,
    verifyOTP,
  };

  return <NewCitizenContext.Provider value={payload}>{children}</NewCitizenContext.Provider>;
};

export default NewCitizenProvider;
