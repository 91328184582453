import React, { useContext } from "react";
import { SearchContext } from "./SearchContext";
import SearchPage from "./SearchPage";
import LoginForm from "./components/LoginForm";

const SearchComponent = () => {
  const { state } = useContext(SearchContext);

  return <>{state.isAuthenticated ? <SearchPage /> : <LoginForm />}</>;
};
export default SearchComponent;
