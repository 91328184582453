/* eslint-disable no-case-declarations */
import React, { useEffect, useReducer, createContext, useRef, useCallback, useState } from "react";
import { Form } from "antd";
import genderOptions from "../../jsonLib/gender_options.json";
import allProvince from "../../jsonLib/allProvince.json";
import allMunicipalities from "../../jsonLib/allMunicipalities.json";
import moment from "moment";
import {
  getBarangayOption,
  uploadCitizenPhoto,
} from "../../services/public_registration.service";

const userLocalKey = "searchUser";

const defaultState = {
  isAuthenticated: false,
  isInitialized: false,
  user: {
    access: null,
    refresh: null,
  },
  citizenDetails: {
    first_name: "",
    last_name: "",
    middle_name: "",
    name_extension: "",
    birthdate_year: "",
    birthdate_month: "",
    birthdate_day: "",
    gender: null,
    phone_number: "",
    is_voter: null,
    barangay: null,
    address: "",
    municipality: null,
    email: "",
    province: null,
    profile: "",
    principal: "",
    is_verified: false,
  },
};

const defaultStateFn = key => {
  return key ? defaultState[key] : defaultState;
};

const stateDispatchReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      return { ...state, isInitialized: true };

    case "LOGIN":
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, isInitialized: true, user };

    case "LOGOUT":
      return { ...defaultState, isInitialized: true };

    default: {
      if (action.key) {
        if (typeof action.payload === "object") {
          return {
            ...state,
            [action.key]: {
              ...state[action.key],
              ...action.payload,
            },
          };
        }

        return {
          ...state,
          [action.key]: action.payload,
        };
      }

      return state;
    }
  }
};

export const SearchContext = createContext(defaultState);

const SearchProvider = ({ children }) => {
  const [state, stateDispatch] = useReducer(stateDispatchReducer, defaultStateFn());
  const isVerified = useRef(false);
  const [provinceOptions, setProvinceOptions] = useState();
  const [selectedProvince, setSelectedProvince] = useState();
  const [municipalityOptions, setMunicipalityOptions] = useState();
  const [selectedMunicipality, setSelectedMunicipality] = useState();
  const [barangayOptions, setBarangayOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [citizenData, setCitizenData] = useState([]);

  const login = useCallback(user => {
    localStorage.setItem(userLocalKey, JSON.stringify(user));
    stateDispatch({ type: "LOGIN", payload: { user } });

    return user;
  }, []);

  const logout = useCallback(() => {
    try {
      localStorage.removeItem(userLocalKey);
      stateDispatch({ type: "LOGOUT" });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const verify = useCallback(async () => {
    try {
      const userData = JSON.parse(localStorage.getItem(userLocalKey));

      if (!userData) {
        throw new Error("Unauthenticated!");
      }

      login(userData);
    } catch (error) {
      stateDispatch({ type: "INITIALIZE" });
      console.warn(error);
      localStorage.removeItem(userLocalKey);
    }
  }, []);

  useEffect(() => {
    if (!isVerified.current) {
      verify();
      isVerified.current = true;
    }
  }, []);

  const [form] = Form.useForm();

  function formatPhoneNumber(value) {
    // format the input value as a Philippine phone number
    if (!value) return "";
    value = value.toString();
    if (value.startsWith("0")) return value.replace(/^0/, "63");
    if (value.startsWith("63")) return value;

    // setVal(`63${value}`);
    return `63${value}`;
  }

  function parsePhoneNumber(value) {
    // parse the input value as a number
    if (!value) return 0;
    return value.toString().replace(/^(0|63)/, "");
  }

  useEffect(() => {
    const filteredProvince = allProvince.map(province => ({
      ...province,
      label: province?.fields.name,
      value: province?.pk,
    }));

    setProvinceOptions(filteredProvince);
  }, []);

  useEffect(() => {
    if (selectedProvince) {
      const filteredMunicipality = allMunicipalities
        .filter(municipality => municipality?.fields?.province === selectedProvince)
        .map(municipality => ({
          ...municipality,
          label: municipality?.fields.name,
          value: municipality?.pk,
        }));
      form.setFieldsValue({ municipality: undefined });
      setSelectedMunicipality(null);
      setMunicipalityOptions(filteredMunicipality);
    }
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedMunicipality) {
      setIsLoading(true);
      setBarangayOptions([]);
      const fetchBarangay = async () => {
        const barangayOptions = await getBarangayOption(selectedMunicipality);
        const filteredBarangayOption = barangayOptions.results.map(barangay => ({
          ...barangay,
          label: barangay?.name,
          value: barangay?.id,
        }));
        setIsLoading(false);
        setBarangayOptions(filteredBarangayOption);
      };

      fetchBarangay().catch(console.error);
    }
  }, [selectedMunicipality]);

  const uploadPhoto = async () => {
    const isUpload = await uploadCitizenPhoto(state.citizenDetails.profile);
    return isUpload;
  };

  const transformCitizenDetailsToParams = () => {
    const citizenDetailsToParam = { ...state.citizenDetails };
    delete citizenDetailsToParam.birthdate_year;
    delete citizenDetailsToParam.birthdate_month;
    delete citizenDetailsToParam.birthdate_day;

    citizenDetailsToParam.birth_date = moment(
      `${state.citizenDetails.birthdate_year}-${state.citizenDetails.birthdate_month}-${state.citizenDetails.birthdate_day}`,
      "YYYY-MM-DD",
      false,
    ).format("YYYY-MM-DD");

    return citizenDetailsToParam;
  };

  const payload = {
    state,
    login,
    verify,
    logout,
    stateDispatchMain: stateDispatch,
    provinceOptions,
    municipalityOptions,
    setSelectedProvince,
    setSelectedMunicipality,
    barangayOptions,
    selectedProvince,
    selectedMunicipality,
    formatPhoneNumber,
    parsePhoneNumber,
    uploadPhoto,
    isLoading,
    genderOptions,
    transformCitizenDetailsToParams,
    citizenData,
    setCitizenData,
  };

  return <SearchContext.Provider value={payload}>{children}</SearchContext.Provider>;
};

export default SearchProvider;
