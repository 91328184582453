import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  WizardContainer,
  Button,
  ButtonContainer,
  WizardSteps,
  WizardStep,
  FormContainer,
  Sidebar,
  Topbar,
  MobileWizardStepsContainer,
  MobileWizardSteps,
  MobileButtonContainer,
} from "../wizard/wizard.styled";
import { Form } from "antd";

const WizardForm = ({ steps, disableClick = true, formRefFromProps = null }) => {
  const [currentStep, setCurrentStep] = useState(0); // tmp; 0
  const [formData, setFormData] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef();
  const [, setStepValidationStatus] = useState({});
  const [isStepValid, setIsStepValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Initialize validation status for each step
    const initialValidationStatus = steps.reduce(
      (acc, step, index) => ({
        ...acc,
        [index]: true,
      }),
      {},
    );
    setStepValidationStatus(initialValidationStatus);
  }, [steps]);

  const handleInputChange = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
    }));
  };

  const handleStepClick = step => {
    setCurrentStep(step);
  };

  const validateAllFields = async () => {
    try {
      const isValid = await form.validateFields();
      setIsStepValid(isValid);
      return isValid;
    } catch (error) {
      console.log("form validation failed:", error);
    }
  }

  const handleNextStep = async () => {
    try {
      const isValid = await validateAllFields();
      if (isValid) {
        const asyncValidator = steps[currentStep]?.asyncValidator;
        const asyncFn = steps[currentStep]?.asyncFn;
        if (asyncFn) {
          setIsLoading(true);
          await asyncFn();
          setIsLoading(false);
        }
        if (asyncValidator) {
          setIsLoading(true);
          const asyncValidatorResult = await asyncValidator();
          setIsLoading(false);
          if (asyncValidatorResult === false) {
            return; // Do not proceed to the next step
          }
        }
        setCurrentStep(prevStep => prevStep + 1);
      }
    } catch (error) {
      console.log("form validation failed:", error);
    }
  };

  const handlePreviousStep = async () => {
    const isValid = await validateAllFields();
    if (isValid) {
      setCurrentStep(prevStep => prevStep - 1);
    }
  };

  const handleFormChange = () => {
    // Update step validation status on form change
    const currentStepErrors = form.getFieldsError().some(field => field.errors.length > 0);
    setIsStepValid(!currentStepErrors);
    setStepValidationStatus(prevStatus => ({
      ...prevStatus,
      [currentStep]: !currentStepErrors,
    }));
  };

  const renderStepContent = () => {
    const StepComponent = steps[currentStep].component;

    return (
      <div className="step-component-container">
        <FormContainer
          form={form}
          ref={(ref) => {
            formRef.current = ref;
            formRefFromProps.current = ref;
          }}
          onFieldsChange={handleFormChange}
        >
          {!steps[currentStep].hideTitle && (
            <Topbar className="step-component-title">
              <p style={{ margin: steps[currentStep].centerTitle ? "0 auto" : null }}>{steps[currentStep].title}</p>
            </Topbar>
          )}
          <StepComponent form={form} formData={formData} onInputChange={handleInputChange} />
          <ButtonContainer>
            {currentStep > 0 && !steps[currentStep].hideBackBtn && (
              <Button
                type={steps[currentStep].isSocial ? "social-service" : "primary"}
                onClick={handlePreviousStep}
                disabled={isLoading}>
                {isLoading ? "Loading..." : "Back"}
              </Button>
            )}
            {!steps[currentStep].hideNextBtn && (
              <Button
                type={steps[currentStep].isSocial ? "social-service" : "primary"}
                onClick={handleNextStep}
                disabled={!isStepValid || isLoading}>
                {isLoading ? "Loading..." : steps[currentStep]?.btnNextOverwriteTitle || "Next"}
              </Button>
            )}
          </ButtonContainer>
        </FormContainer>
      </div>
    );
  };

  return (
    <WizardContainer>
      <Sidebar>
        <img className="logo-sidebar" src="/static/images/logo.png" />
        <WizardSteps
          current={currentStep}
          size="small"
          onChange={handleStepClick}
          direction="vertical"
          responsive={false}>
          {steps.map((step, index) => (
            <WizardStep key={index} title={step.title} disabled={disableClick} />
          ))}
        </WizardSteps>
        <img className="bg" src="/static/images/bg-bottom.png" />
      </Sidebar>
      {renderStepContent()}
      <MobileWizardStepsContainer>
        <MobileButtonContainer>
          {currentStep > 0 && !steps[currentStep].hideBackBtn && (
            <Button className="ant-btn-default" onClick={handlePreviousStep} disabled={isLoading}>
              {isLoading ? "Loading..." : "Back"}
            </Button>
          )}
          {!steps[currentStep].hideNextBtn && (
            <Button
              type={steps[currentStep].isSocial ? "social-service" : "primary"}
              onClick={handleNextStep}
              disabled={!isStepValid || isLoading}>
              {isLoading ? "Loading..." : steps[currentStep]?.btnNextOverwriteTitle || "Next"}
            </Button>
          )}
        </MobileButtonContainer>
        {!steps[currentStep].hideWizardSteps && (
          <MobileWizardSteps
            className="wizard-ant-steps"
            current={currentStep}
            size="small"
            onChange={handleStepClick}
            direction="horizontal"
            responsive={false}>
            {steps.map((step, index) => (
              <WizardStep key={index} disabled={disableClick} />
            ))}
          </MobileWizardSteps>
        )}
      </MobileWizardStepsContainer>
    </WizardContainer>
  );
};

WizardForm.propTypes = {
  steps: PropTypes.array,
  onSubmit: PropTypes.func,
  disableClick: PropTypes.bool,
};

export default WizardForm;
