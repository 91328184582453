import styled from "styled-components";
import bottomBackground from "../../../assets/tingog_signup_bottom_background.png";
import registerHeaderMobile from "../../../assets/bagong_pilipinas_register_header.png";
import { Button } from "antd";

const NavContainer = styled.nav`
  width: 100%;
  height: 3rem;
  padding: 5px 5px;

  .logo {
    display: flex;
    height: 100%;
    justify-content: flex-end;
  }
`;

const RegisterContainer = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (min-width: 768px) {
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    // display: none;
    align-items: center;
  }
`;

const RegisterHomePageContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column; 
  background-color: #F9F9F9;

  .mobile-bg {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0rem;

    @media only screen and (min-width: 320px) {
      bottom: -4rem;
    }

    @media only screen and (min-width: 390px) {
      bottom: 0rem;
    }

    @media only screen and (min-width: 480px) and (orientation: portrait) {
      bottom: -9.5rem;
    }

    @media only screen and (min-width: 768px) {
      bottom: -17.5rem;
    }

    @media only screen and (min-width: 900px) {
      display: none;
    }
  }

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    .mobile-banner {
      display: none;
    }
  }

  .mobile-gradient {
    width: 100%;

    @media only screen and (min-width: 900px) {
      display: none;
    }
  }

  .left-side-container {
    flex: 1; 
    display: none; 

    @media only screen and (min-width: 900px) {
      background-image: url(/static/images/bg1.png); 
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: contain; 
    }

    @media only screen and (min-width: 1240px) {
      display: block; 
      flex: 2;
    }
  }

  .right-side-container {
    z-index: 99;
    display: flex;
    height: 80vh;
    flex-direction: column;
    margin: 0 1.5rem;
    flex: 1; 
    justify-content: center;
    background-image: url(/static/images/bg1.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    gap: 2rem;

    @media only screen and (min-width: 768px) {
      gap: 5.5rem;
      height: auto;
      align-items: center;
    }

    @media only screen and (min-width: 1240px) {
      background: none;
      flex-direction: column;
      align-items: center; 
      margin: 0 auto;
      padding: 4rem 2rem; 
    }
  }

  .bp-logo{
    width: 100px;
    max-width: 700px;
    height: auto;
    align-self: center;

    @media only screen and (min-width: 768px) {
      width: 125px;
      display: block;
    }

    @media only screen and (min-width: 1240px) {
      width: 150px;
    }
  }

  .text-container{
    width: 60%;
    align-self: center;
    h2 {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      width: 100%;
      margin: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    .text-container {
      h2 {
        font-size: 28px;
        font-weight: 700;
        text-align: center;
      }
    }
  
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    button {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 1rem 2rem;
      border-radius: 1rem;
      display: flex;
      align-items: center;
      gap: 1rem;
      img {
        width: 34px;
        height: auto;
      }
    }
  }
  
`;

const RegisterHeader = styled.div`
  width: 100%;
  height: 145px;
  background-image: url(${registerHeaderMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const StepHeader = styled.div`
  width: 100%;
  height: 145px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const RegisterFooter = styled.div`
  @media only screen and (min-width: 768px) {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 5%;
    background-image: url(${bottomBackground});
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 2;
  }
`;

const RegisterHomePageFooter = styled.div`
  display: block;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5%;
  background-image: url(${bottomBackground});
  background-size: cover;
  background-repeat: no-repeat;
`;

const RegisterFormContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const RegisterLogoContainer = styled.div`
  text-align: center;
  padding: 44px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

const TermsAndConditionLogoContainer = styled.div`
  padding: 44px;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    position: initial;
    min-width: 15rem;
    max-width: 20rem;
  }
`;

const RegisterLogo = styled.img`
  width: 80px;
  height: auto;

  @media only screen and (min-width: 768px) {
    width: 167px;
    margin: 0 auto;
    display: flex;
  }
`;

const UploadImageAvatar = styled.img`
  border-radius: 50%;
  min-height: 130px;
  max-height: 130px;
  object-fit: cover;
  min-width: 130px;
  max-width: 130px;
`;

const UploadButton = styled(Button)``;

const RegisterStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const OtpStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const UploadImageStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
  }
`;

const DownloadIdStepContainer = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 1.5rem;
  height: calc(100vh - 10rem - 3rem - 145px - 10px);
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  width: 100%;
  text-align: center;

  @media only screen and (min-width: 768px) {
    height: fit-content;
    width: 100%;
    padding: 0;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin: 0 20px;
    position: relative;
    z-index: 99;

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .header-text {
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        width: 80%;

        @media only screen and (min-width: 768px) {
          width: 100%;
          font-size: 2rem;
          font-weight: 600;
        }
      }

      .help-text {
        .help-text-download {
          color: #2d378c;
          font-weight: 700;
        }

        @media only screen and (max-width: 767px) {
          display: none;
        }
      }
    }

    .id {
      width: 100%;
      @media only screen and (min-width: 768px) {
        max-width: 30rem;
        height: auto;
      }
    }

    .download-id-btn {
      background-color: white;
      color: #2d378c;
      font-weight: 600;
      border: 2px solid #2d378c;
      border-radius: 8px;
      padding: 0.5rem 0;
      width: 100%;
      text-align: center;
      cursor: pointer;

      @media only screen and (min-width: 768px) {
        min-width: fit-content;
        max-width: 15rem;
        background-color: #2d378c;
        color: #fff;
        font-weight: 400;
        border-radius: 4px;

        &:disabled {
          background-color: #e0e0e0;
          color: #000000;
          border-color: #e0e0e0;
        }
      }
    }
  }

  .id-card-download {
    .btn-container {
      display: flex;
      justify-content: center;

      @media only screen and (min-width: 768px) {
        display: none;
      }

      .submit-btn {
        span {
          font-weight: 400;
        }
      }
    }
  }

  .register-again-btn {
    width: 100%;
    min-width: fit-content;
    max-width: 15rem;
    background-color: #2d378c;
    color: #fff;
    font-weight: 400;
    border: 2px solid #2d378c;
    border-radius: 8px;
    padding: 0.5rem 0;
    text-align: center;
    cursor: pointer;

    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }
`;

const LastStepContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 80vh;

  a {
    color: #000000;
    text-decoration: underline;
  }

  @media only screen and (min-width: 768px) {
    text-align: center;
  }
`;
const RegisterTermsAndConditionContainer = styled.div`
  width: 100%;
  height: 100%;

  .terms-text-container {
    padding: 0 20px;

    @media only screen and (min-width: 768px) {
      padding: 0;
    }
  }

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

const TermsAndConditionButtonContainer = styled.div`
  background-color: #2d378c;
  position: fixed;
  bottom: 0;
  padding: 0 20px;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10rem;
  width: 100%;

  .checkbox-container {
    display: flex;
    justify-content: center;
    gap: 1rem;

    .agreement-text {
      margin: 0;
      color: #fff;
      width: fit-content;
    }

    .ant-form-item-control-input {
      display: flex;
      align-items: flex-start;
    }
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    height: 10rem;
    padding: 0;
    padding-left: 20rem;
    justify-content: flex-start;

    .checkbox-container {
      align-items: center;
      gap: 1rem;

      .agreement-text {
        margin-right: 2rem;
      }

      .ant-form-item {
        margin: 0;
      }

      .ant-form-item-control-input {
        align-items: center;
      }
    }
  }
`;

const TermsAndConditionTitle = styled.div`
  display: flex;
  color: #192b8b;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;

  @media only screen and (min-width: 768px) {
    height: 10rem;
    font-size: 2.5vw;
    justify-content: center;
    align-items: center;
  }
`;

const TermsAndConditionContent = styled.div`
  overflow-y: scroll;
  margin-top: 10px;
  padding-bottom: 5rem;
  height: calc(100vh - 10rem - 3rem - 145px);
  display: flex;
  flex-direction: column;
  gap: 1rem;

  p,
  li,
  ol,
  ul {
    text-align: justify;
    margin: 0;
  }

  @media only screen and (min-width: 768px) {
    width: calc(100% - 1rem);
    height: calc(100vh - 20rem);
    padding-right: 2.5rem;

    scrollbar-width: none;
    &::-webkit-scrollbar {
      background: transparent;
      width: 0.35rem;
    }

    &::-webkit-scrollbar-thumb {
      background: lightgrey;
      border-radius: 10px;
    }
  }
`;

export {
  NavContainer,
  RegisterContainer,
  RegisterFormContainer,
  RegisterHeader,
  StepHeader,
  RegisterFooter,
  RegisterHomePageContainer,
  RegisterHomePageFooter,
  RegisterLogo,
  RegisterLogoContainer,
  TermsAndConditionLogoContainer,
  UploadImageAvatar,
  UploadButton,
  RegisterStepContainer,
  OtpStepContainer,
  UploadImageStepContainer,
  DownloadIdStepContainer,
  LastStepContainer,
  TermsAndConditionButtonContainer,
  RegisterTermsAndConditionContainer,
  TermsAndConditionTitle,
  TermsAndConditionContent,
};
