import React, { useContext } from "react";
import { RegisterContainer, RegisterLogoContainer, RegisterLogo } from "../components/Register.styled";
import WizardForm from "../../../components/form/wizard";
import PersonalInformation from "./steps/PersonalInformation";
import PersonalAddress from "./steps/PersonalAddress";
import OTPVerification from "./steps/OTPVerification";
import Organization from "./steps/Organization";
import UploadImage from "./steps/UploadImage";
import IDCard from "./steps/IDCard";
import { NewCitizenContext } from "./NewCitizenContext";

const NewCitizensComponent = () => {
  const { checkUserExist, sendOTPMobile, verifyOTP, registerCitizenDetails, formRef } = useContext(NewCitizenContext);

  const steps = [
    {
      title: "Personal Information",
      component: PersonalInformation,
      hideBackBtn: true,
      asyncValidator: checkUserExist,
    },
    {
      title: "Address",
      component: PersonalAddress,
    },
    {
      title: "Organization",
      component: Organization,
      asyncFn: sendOTPMobile,
    },
    {
      title: "Verification",
      centerTitle: true,
      component: OTPVerification,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: verifyOTP,
    },
    {
      title: "Upload Image",
      centerTitle: true,
      component: UploadImage,
      btnNextOverwriteTitle: "Ipadala",
      asyncValidator: registerCitizenDetails,
    },
    {
      title: "Download ID Card",
      centerTitle: true,
      component: IDCard,
      hideBackBtn: true,
      hideWizardSteps: true,
      hideNextBtn: true,
    },
  ];

  return (
    <RegisterContainer>
      <RegisterLogoContainer>
        <RegisterLogo src="/static/images/logo.png" alt="logo" />
      </RegisterLogoContainer>
      <WizardForm steps={steps} formRefFromProps={formRef} />
    </RegisterContainer>
  );
};

export default NewCitizensComponent;
